import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'

export default function Mint({
    address,
    network,
    contract,
    updateBalance,
    PRICE,
    PUBLIC_LIMIT,
    PRESALE_LIMIT,
}) {
    // Stats
    const [totalMinted, setTotalMinted] = useState(0)
    const [totalMintedLoading, setTotalMintedLoading] = useState(false)
    const [batchSupply, setBatchSupply] = useState(1000)
    const [presaleTokens, setPresaleTokens] = useState(0)
    const [saleLive, setSaleLive] = useState(false)
    const [presaleLive, setPresaleLive] = useState(false)

    // Form input
    const [mintAmount, setMintAmount] = useState(1)
    const [loadingState, setLoadingState] = useState(false)
    const [nonce, setNonce] = useState(Math.floor(Math.random() * 100000000))

    // Updated stats from contract
    useEffect(() => {
        if (!contract || !network || !address) return
        getSaleStatus()
        getMintedStats()
        saleStatusListener()
    }, [contract, network, address])

    // Listen for sale status toggle events
    async function saleStatusListener() {
        contract.on('ToggleSale', (sale, presale) => {
            setSaleLive(sale)
            setPresaleLive(presale)
        })
    }

    // Get presale and sale status
    async function getSaleStatus() {
        if (!contract) return
        try {
            setSaleLive(await contract.saleLive())
            setPresaleLive(await contract.presaleLive())
        } catch (e) {
            console.log(e)
        }
    }

    // Get total minted and user's token balance
    async function getMintedStats() {
        if (!contract || !address) return
        try {
            setTotalMinted((await contract.totalSupply()).toString())
            setBatchSupply((await contract.batchSupply()).toString())
            setPresaleTokens(
                (await contract.presaleTokensClaimed(address)).toString()
            )
            updateBalance()
        } catch (e) {
            console.log(e)
        }
    }

    // Update total minted
    async function getTotalMinted() {
        if (!contract) return
        try {
            setTotalMintedLoading(true)
            setTotalMinted((await contract.totalSupply()).toString())
            setTotalMintedLoading(false)
        } catch (e) {
            console.log(e)
            setTotalMintedLoading(false)
        }
    }

    // Mint functions
    async function mint() {
        try {
            // Send transaction
            setLoadingState('Sending transaction...')
            let transaction = await contract.mint(mintAmount, {
                value: ethers.utils.parseEther(`${mintAmount * PRICE}`),
            })

            // Wait for transaction to process
            setLoadingState('Processing...')
            let receipt = await transaction.wait()

            // Transaction complete
            setLoadingState(
                receipt ? 'Success! Mint again?' : 'Canceled. Try again?'
            )
            getMintedStats()
            updateBalance()
        } catch (e) {
            handleError(e)
        }
    }
    async function presaleMint() {
        try {
            // Create signature
            setLoadingState('Checking whitelist...')
            let response = await fetch(
                `https://mint.nftfc.club/api/signature-presale`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        address,
                        mintAmount,
                        nonce,
                    }),
                }
            )
            const data = await response.json()

            // Handle error message
            if (data.status === 'error') {
                setLoadingState(data.message)
                return
            }
            let { signature, hashWithHeader } = data
            if (!hashWithHeader || !signature) return

            // Send transaction
            setLoadingState('Sending transaction...')
            let transaction = await contract.presaleMint(
                hashWithHeader,
                signature,
                nonce,
                mintAmount,
                {
                    value: ethers.utils.parseEther(`${mintAmount * PRICE}`),
                }
            )

            // Wait for transaction to process
            setLoadingState('Processing...')
            let receipt = await transaction.wait()

            // Transaction complete
            setLoadingState(
                receipt ? 'Success! Mint again?' : 'Canceled. Try again?'
            )
            getMintedStats()
            updateBalance()
            setNonce(nonce + 1)
        } catch (e) {
            handleError(e)
        }
    }

    // Handle minting errors
    function handleError(e) {
        console.log(e)
        // Not a metamask error, not sure what happened
        if (!e.code) setLoadingState('Unknown error. Try again?')
        // Metamask user denied transaction
        else if (e.code && e.code === 4001)
            setLoadingState('Denied. Try again?')
        // A required statement in the contract failed
        else if (e.error.code && e.error.code === -32000) {
            setLoadingState('Insufficient funds. Try again?')
        } else if (e.error.code && e.error.code === -32603)
            setLoadingState(
                `${e.error.message.replace(
                    'execution reverted: ',
                    ''
                )}. Try again?`
            )
        else setLoadingState('Unknown error. Try again?')
    }

    return (
        <div>
            {/* Supply */}
            {/* <button
                className='flex items-center justify-center mx-auto text-center'
                onClick={() => getTotalMinted()}
                title='Refresh'
            >
                <p className='text-xl font-bold text-gray-800'>
                    {totalMintedLoading ? (
                        <span className='text-lg'>Loading...</span>
                    ) : (
                        <span>
                            {(batchSupply - totalMinted).toLocaleString(
                                'en-US'
                            )}{' '}
                        </span>
                    )}
                </p>
                <span className='ml-2 text-lg text-gray-700'>remaining</span>
            </button> */}

            {/* Quantity selector */}
            <div className='mt-4 flex justify-center items-baseline text-3xl font-bold space-x-5'>
                <button
                    className='border border-gray-600 hover:border-gray-700 transition rounded-full h-8 w-8 flex justify-center items-end bg-gray-200 hover:bg-gray-100 bg-opacity-90'
                    onClick={() => {
                        if (mintAmount > 1) setMintAmount(mintAmount - 1)
                    }}
                >
                    -
                </button>
                <p className='text-7xl'>{mintAmount}</p>
                <button
                    className='border border-gray-600 hover:border-gray-700 transition rounded-full h-8 w-8 flex justify-center items-end bg-gray-200 hover:bg-gray-100 bg-opacity-90'
                    onClick={() => {
                        // set max according to sale status
                        if (presaleLive && !saleLive) {
                            if (mintAmount < PRESALE_LIMIT)
                                setMintAmount(mintAmount + 1)
                        } else if (mintAmount < PUBLIC_LIMIT)
                            setMintAmount(mintAmount + 1)
                    }}
                >
                    +
                </button>
            </div>

            {/* Mint button */}
            <div className='mt-6'>
                <div className='rounded-md shadow'>
                    <button
                        className='w-full flex items-center justify-center px-5 py-3 mb-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900'
                        onClick={() => {
                            if (saleLive) mint()
                            else if (presaleLive) {
                                // Can't be pending signature
                                if (loadingState != 'Checking whitelist...')
                                    presaleMint()
                            }
                            // Sale and presale are closed
                            else setLoadingState('Sales Closed')
                        }}
                    >
                        {!saleLive && !presaleLive ? (
                            'Sales Closed'
                        ) : loadingState ? (
                            loadingState
                        ) : (
                            <span>
                                Mint {mintAmount} NFT
                                {mintAmount === 1 ? '' : 's'}
                            </span>
                        )}
                    </button>
                </div>
            </div>

            {/* Price */}
            <p className='leading-6 font-medium text-gray-700'>
                {PRICE} ETH / NFT
            </p>

            {/* Presale tokens */}
            {/* {presaleLive && (
                <p className='py-2 mt-4 text-gray-700'>
                    {PRESALE_LIMIT - presaleTokens} presale mint
                    {presaleTokens != 1 && 's'} remaining
                </p>
            )} */}
        </div>
    )
}
