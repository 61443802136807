import React, { Fragment, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Toggle from './Toggle'

export default function Admin({
    contract,
    address,
    network,
    updateBalance,
    PRICE,
    PUBLIC_LIMIT,
    PRESALE_LIMIT,
}) {
    // Contract stats
    const [totalMinted, setTotalMinted] = useState(0)
    const [batchSupply, setBatchSupply] = useState(0)
    const [gifted, setGifted] = useState(0)
    const [saleLive, setSaleLive] = useState(false)
    const [presaleLive, setPresaleLive] = useState(false)
    const [nonce, setNonce] = useState(Math.floor(Math.random() * 100000000))
    const [loadingState, setLoadingState] = useState(false)

    // User stats
    const [presaleTokens, setPresaleTokens] = useState(0)

    // Form inputs
    const [batchSupplyInput, setBatchSupplyInput] = useState('')
    const [baseURI, setBaseURI] = useState('')
    const [giftlist, setGiftlist] = useState('')
    const [whitelist, setWhitelist] = useState('')
    const [presaleMintAmount, setPresaleMintAmount] = useState(1)
    const [mintAmount, setMintAmount] = useState(1)

    // Update contract and user stats
    useEffect(() => updateStats(), [contract, network, address])
    async function updateStats() {
        if (!contract || !network || !address) return
        try {
            // Supply status
            setTotalMinted((await contract.totalSupply()).toString())
            setBatchSupply((await contract.batchSupply()).toString())
            setGifted((await contract.giftedAmount()).toString())

            // Sale status
            setSaleLive(await contract.saleLive())
            setPresaleLive(await contract.presaleLive())

            // Tokens claimed
            setPresaleTokens(
                (await contract.presaleTokensClaimed(address)).toString()
            )
            updateBalance()
        } catch (e) {
            console.log(e)
        }
    }

    // Update public and presale start/end times
    async function toggleSale(sale, presale) {
        try {
            let transaction = await contract.toggleSale(sale, presale)
            await transaction.wait()
            updateStats()
        } catch (e) {
            console.log(e)
        }
    }

    // Set BaseURI
    async function updateBaseURI() {
        try {
            await contract.setBaseURI(baseURI)
        } catch (e) {
            console.log(e)
        }
    }

    // Set BatchSupply
    async function updateBatchSupply() {
        try {
            let transaction = await contract.setupBatch(
                ethers.utils.parseEther(`${PRICE}`),
                batchSupplyInput
            )
            await transaction.wait()
            setBatchSupply((await contract.batchSupply()).toString())
        } catch (e) {
            console.log(e)
        }
    }

    // Gift
    async function gift() {
        try {
            const transaction = await contract.gift(
                giftlist.toLowerCase().replace(/s+/g, '').split(',')
            )
            await transaction.wait()
            updateStats()
        } catch (e) {
            console.log(e)
        }
    }

    // Whitelist, isAdd is boolean whether to add or remove from whitelist
    async function updateWhitelist(isAdd) {
        // Adds header automatically, use hash instead of hashWithHeader
        let message = 'Please verify you are the owner. \n\nNonce: '
        let signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [message + nonce, address],
        })
        // Verifying the address:
        // Pass hash without header to network request, header is added automatically
        // let addr = await window.ethereum.request({
        //     method: 'personal_ecRecover',
        //     params: [message, signature],
        // })
        try {
            let url = `https://mint.nftfc.club/api/whitelist-${
                isAdd ? 'add' : 'remove'
            }`
            let response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    nonce,
                    signature,
                    addresses: whitelist
                        .toLowerCase()
                        .replace(/s+/g, '')
                        .split(','),
                }),
            })
            const data = await response.json()
            if (data.status === 'error') {
                console.log(data.message)
                return
            }
            if (data.status === 'success') setWhitelist('')
        } catch (e) {
            console.log(e)
        }
    }

    // Withdraw
    async function withdraw() {
        try {
            let transaction = await contract.withdraw()
            await transaction.wait()
        } catch (e) {
            console.log(e)
        }
    }

    // Mint
    async function mint() {
        console.log('minting')
        try {
            setLoadingState('Sending transaction...')
            let transaction = await contract.mint(mintAmount, {
                value: ethers.utils.parseEther(`${mintAmount * PRICE}`),
            })
            setLoadingState('Transaction processing...')
            await transaction.wait()
            setLoadingState('Complete')
            updateStats()
            updateBalance()
        } catch (e) {
            console.log(e)
        }
    }

    // Presale mint
    async function presaleMint() {
        console.log('presale minting')
        try {
            setLoadingState('Signing...')
            let response = await fetch(
                `https://mint.nftfc.club/api/signature-presale`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        address,
                        mintAmount,
                        nonce,
                    }),
                }
            )
            const data = await response.json()
            if (data.status === 'error') {
                console.log(data.message)
                return
            }
            setLoadingState('Sending transaction...')
            let { signature, hashWithHeader } = data
            if (!hashWithHeader || !signature) return
            let transaction = await contract.presaleMint(
                hashWithHeader,
                signature,
                nonce,
                mintAmount,
                {
                    value: ethers.utils.parseEther(`${mintAmount * PRICE}`),
                }
            )
            setLoadingState('Transaction processing...')
            await transaction.wait()
            setLoadingState('Complete')
            updateStats()
            updateBalance()
            setNonce(nonce + 1)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <form
            className='space-y-6 shadow rounded p-8 bg-gray-100 flex flex-col justify-center items-center'
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            {/* Welcome message */}
            <div className='space-y-2'>
                <h2 className='text-3xl font-medium leading-6 text-gray-900'>
                    Admin Controls
                </h2>
                <h3 className='text-xl'>
                    {totalMinted} / 10,000
                    <span className='text-gray-400 text-base block'>
                        Minted
                    </span>
                </h3>
            </div>

            {/* Toggle sale status */}
            <div className='space-y-1'>
                <h4 className='text-2xl font-semibold'>
                    {saleLive ? (
                        <>
                            Sale <span className='text-green-500'>Live</span>
                        </>
                    ) : presaleLive ? (
                        <>
                            Presale <span className='text-green-500'>Live</span>
                        </>
                    ) : (
                        <>
                            Sale <span className='text-red-500'>Closed</span>
                        </>
                    )}
                </h4>
                <div className='flex space-x-2 justify-center'>
                    <p>Sale Status</p>
                    <Toggle
                        enabled={saleLive}
                        setEnabled={(status) => toggleSale(status, presaleLive)}
                    />
                </div>
                <div className='flex space-x-2 justify-center'>
                    <p>Presale Status</p>
                    <Toggle
                        enabled={presaleLive}
                        setEnabled={(status) => toggleSale(saleLive, status)}
                    />
                </div>
            </div>

            {/* Set batch supply */}
            <div>
                <div className='col-span-3 sm:col-span-2'>
                    <label
                        htmlFor='batchsupply'
                        className='block text-md font-medium text-gray-700'
                    >
                        Set Batch Supply{' '}
                        <span className='pt-1 text-sm font-medium text-gray-700'>
                            (current: {batchSupply})
                        </span>
                    </label>
                    <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                            type='text'
                            name='batchsupply'
                            id='batchsupply'
                            className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block sm:w-96 h-10 rounded-md sm:text-sm border-gray-300 p-1 text-center'
                            placeholder='1000'
                            value={batchSupplyInput}
                            onChange={(e) => {
                                setBatchSupplyInput(e.target.value)
                            }}
                        />
                    </div>
                    <button
                        type='button'
                        className='inline-flex items-center ml-4 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={() => updateBatchSupply()}
                    >
                        Update
                    </button>
                </div>
            </div>

            {/* Set baseURI */}
            <div>
                <div className='col-span-3 sm:col-span-2'>
                    <label
                        htmlFor='baseuri'
                        className='block text-md font-medium text-gray-700'
                    >
                        Set BaseURI
                    </label>
                    <div className='mt-1 flex rounded-md shadow-sm'>
                        <input
                            type='text'
                            name='baseuri'
                            id='baseuri'
                            className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block sm:w-96 h-10 rounded-md sm:text-sm border-gray-300 p-1 text-center'
                            placeholder='ipfs://QmTrnxbqkzS4bvH/'
                            value={baseURI}
                            onChange={(e) => {
                                setBaseURI(e.target.value)
                            }}
                        />
                    </div>
                    <button
                        type='button'
                        className='inline-flex items-center ml-4 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        onClick={() => updateBaseURI()}
                    >
                        Update
                    </button>
                </div>
            </div>

            {/* Send Gift */}
            <div>
                <label
                    htmlFor='gift-address'
                    className='block text-md font-medium text-gray-700'
                >
                    Send Gifts{' '}
                    <span className='pt-1 text-sm font-medium text-gray-700'>
                        ({gifted} sent)
                    </span>
                </label>
                <div className='mt-1 w-full'>
                    <textarea
                        id='whitelist'
                        name='whitelist'
                        rows={3}
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border border-gray-300 rounded-md sm:w-96 p-1'
                        placeholder='0x12345678901234567890'
                        value={giftlist}
                        onChange={(e) => setGiftlist(e.target.value)}
                    />
                </div>
                <button
                    type='button'
                    className='inline-flex items-center ml-4 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => gift()}
                >
                    Send Gifts
                </button>
            </div>

            {/* Update Whitelist */}
            <div>
                <label
                    htmlFor='whitelist'
                    className='block text-md font-medium text-gray-700'
                >
                    Update Whitelist
                </label>
                <div className='mt-1'>
                    <textarea
                        id='whitelist'
                        name='whitelist'
                        rows={3}
                        className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:w-96 sm:text-sm border border-gray-300 rounded-md p-1'
                        placeholder='0x12345678901234567890'
                        value={whitelist}
                        onChange={(e) => setWhitelist(e.target.value)}
                    />
                </div>
                <button
                    type='button'
                    className='inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => updateWhitelist(true)}
                >
                    Add to Whitelist
                </button>
                <button
                    type='button'
                    className='inline-flex items-center ml-4 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400'
                    onClick={() => updateWhitelist(false)}
                >
                    Remove from Whitelist
                </button>
            </div>

            {/* Presale and Public Mint */}
            {saleLive ? (
                // Public mint
                <div className='flex items-end'>
                    <div>
                        <Dropdown
                            title='Public Mint'
                            value={mintAmount}
                            onChange={setMintAmount}
                            limit={PUBLIC_LIMIT}
                        />
                    </div>
                    <div className=''>
                        <button
                            type='button'
                            className='inline-flex items-center ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => mint()}
                        >
                            {loadingState ? loadingState : 'Mint'}
                        </button>
                    </div>
                </div>
            ) : presaleLive ? (
                // Presale mint
                <div className='flex items-end'>
                    <div>
                        <Dropdown
                            title='Presale Mint'
                            value={presaleMintAmount}
                            onChange={setPresaleMintAmount}
                            limit={PRESALE_LIMIT}
                        />
                    </div>
                    <div className=''>
                        <button
                            type='button'
                            className='inline-flex items-center ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => presaleMint()}
                        >
                            {loadingState ? loadingState : 'Mint'}
                        </button>
                    </div>
                </div>
            ) : (
                // Sale and presale not active
                ''
            )}

            {/* Withdraw funds */}
            <button
                type='button'
                className='inline-flex items-center ml-4 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-300 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400'
                onClick={() => withdraw()}
            >
                Withdraw ETH to Vault
            </button>

            {/* Show user stats */}
            <div>
                {presaleLive && (
                    <p className='text-md font-medium text-gray-700'>
                        You have used {presaleTokens} presale token
                        {presaleTokens === 1 ? ' ' : 's '}.
                    </p>
                )}
            </div>
        </form>
    )
}

function Dropdown({ title, value, onChange, limit }) {
    const [nums, setNums] = useState([])

    useEffect(() => {
        let arr = []
        for (let i = 1; i <= limit; i++) arr.push(i)
        setNums(arr)
    }, [limit])

    return (
        <Listbox value={value} onChange={onChange}>
            {({ open }) => (
                <>
                    <Listbox.Label className='block text-md font-medium text-gray-700 text-center'>
                        {title}
                    </Listbox.Label>
                    <div className='mt-1 w-24'>
                        <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                            <span className='block truncate'>{value}</span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon
                                    className='h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Listbox.Options className='absolute z-10 mt-1 w-24 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                {nums.map((num) => (
                                    <Listbox.Option
                                        key={num}
                                        className={({ active }) =>
                                            `${
                                                active
                                                    ? 'text-white bg-indigo-600'
                                                    : 'text-gray-900'
                                            }
                                                            cursor-default select-none relative py-2 pl-3 pr-9`
                                        }
                                        value={num}
                                    >
                                        {({ value, active }) => (
                                            <>
                                                <span
                                                    className={`
                                                                ${
                                                                    value
                                                                        ? 'font-semibold'
                                                                        : 'font-normal'
                                                                }
                                                                block truncate`}
                                                >
                                                    {num}
                                                </span>

                                                {value ? (
                                                    <span
                                                        className={`${
                                                            active
                                                                ? 'text-white'
                                                                : 'text-indigo-600'
                                                        }
                                                                        absolute inset-y-0 right-0 flex items-center pr-4`}
                                                    >
                                                        <CheckIcon
                                                            className='h-5 w-5'
                                                            aria-hidden='true'
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
