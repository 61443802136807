import React, { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import NFT from '../artifacts/contracts/NFTFC.sol/NFTFC.json'
import Admin from './Admin'
import Assets from './Assets'
import Mint from './Mint'
import Logo from '../images/NFT-FC-BLACK.png'

const ownerAddress = '0x2f77A4a08Ca95661A9f89E9997520e39ebCEFe91'
const isProd = true
const rinkebyContract = '0xC9D849d1E28351D47930c872ef1Bf770Bf137Fd9' // rinkeby
const contractAddress = '0xd7425fFe949Dcc6Bf9fE95f7B7a81a0cd21C9447' // mainnet
const PRICE = 0.05
const PUBLIC_LIMIT = 20
const PRESALE_LIMIT = 4
const PRESALE_START = 1637794800000
const PUBLIC_START = 1637838000000

export default function Layout() {
    const [metaMaskInstalled, setMetaMaskInstalled] = useState()
    const [provider, setProvider] = useState()
    const [contract, setContract] = useState()
    const [network, setNetwork] = useState()
    const [address, setAddress] = useState()
    const [ensName, setEnsName] = useState()
    const [bgColor, setBgColor] = useState('#92cec9')
    const [balance, setBalance] = useState(0)
    const [password, setPassword] = useState('')

    // Initialize app state
    useEffect(() => {
        if (!window) return
        if (!window.ethereum || !window.ethereum.isMetaMask)
            setMetaMaskInstalled(false)
        else {
            setMetaMaskInstalled(true)
            initialize()
        }
    }, [])

    // Update background color
    const colors = ['#f794bd', '#66908c', '#0098dd', '#92cec9']
    useEffect(() => {
        setTimeout(() => {
            let filtered = colors.filter((color) => color != bgColor)
            let newColor = filtered[Math.floor(Math.random() * filtered.length)]
            setBgColor(newColor)
        }, 5000)
    }, [bgColor])

    // Initialize contract and provider
    async function initialize() {
        // Setup ethers contract
        let provider = await new ethers.providers.Web3Provider(
            window.ethereum,
            'any'
        )
        setProvider(provider)
        setContract(
            new ethers.Contract(
                isProd ? contractAddress : rinkebyContract,
                NFT.abi,
                provider.getSigner()
            )
        )
        setNetwork(await provider.getNetwork())

        // Check if a wallet is already connected
        try {
            let accounts = await window.ethereum.request({
                method: 'eth_accounts',
            })
            setAddress(accounts[0])
        } catch (e) {
            console.log(e)
        }

        // Event listener for changing accounts
        window.ethereum.on('accountsChanged', (accounts) => {
            setAddress(accounts[0])
        })

        // Reload window on chain changes
        window.ethereum.on('chainChanged', () => window.location.reload())
    }

    // Request to connect wallet
    async function connectWallet() {
        try {
            let accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            })
            setAddress(accounts[0])
        } catch (e) {
            console.log(e)
        }
    }

    // Lookup ENS name
    useEffect(async () => {
        if (!provider || !address) return
        try {
            let name = await provider.lookupAddress(address)
            if (name) setEnsName(name)
            else setEnsName('')
        } catch (e) {
            // Ignore error that ENS not supported on this network
            if (e.message && e.message.includes('network does not support ENS'))
                return
            console.log(e)
        }
    }, [address])

    // Get balance for current address
    async function updateBalance() {
        if (!contract || !address) return
        try {
            setBalance((await contract.balanceOf(address)).toString())
        } catch (e) {
            console.log(e)
        }
    }

    function getHours(time) {
        return new Date(time - Date.now()).getUTCHours()
    }
    function getMins(time) {
        return new Date(time - Date.now()).getUTCMinutes()
    }

    return (
        <div
            className='h-full w-full min-h-screen transition duration-500 relative'
            style={{ backgroundColor: bgColor }}
        >
            <div className='max-w-7xl mx-auto text-center pb-16 pt-10 px-8'>
                <img src={Logo} className='h-5 mx-auto mb-10' />
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                    <span className='block'>Ready to mint?</span>
                    <span className='block'>It's time to join the club.</span>
                </h2>
                <p className='pt-2 text-gray-700'>
                    {ensName
                        ? ensName
                        : address
                        ? `${address.slice(0, 6)}...${address.slice(
                              address.length - 4
                          )}`
                        : ''}
                </p>

                {/* Countdown timer */}
                <div className='text-xl pt-4'>
                    {Date.now() < PRESALE_START ? (
                        <p>
                            Presale starts in{' '}
                            {getHours(PRESALE_START) < 1 ? (
                                ''
                            ) : (
                                <>
                                    <span className='font-bold'>
                                        {getHours(PRESALE_START)} hour
                                        {getHours(PRESALE_START) == 1
                                            ? ''
                                            : 's'}{' '}
                                    </span>
                                    and{' '}
                                </>
                            )}
                            <span className='font-bold'>
                                {getMins(PRESALE_START)} min
                                {getMins(PRESALE_START) == 1 ? '' : 's'}
                            </span>
                            .
                        </p>
                    ) : Date.now() < PUBLIC_START ? (
                        <p>
                            <span className='font-bold'>
                                Presale{' '}
                                <span className='text-green-800'>Live</span>
                            </span>
                            .
                            <br />
                            Public sale starts in{' '}
                            {getHours(PUBLIC_START) < 1 ? (
                                ''
                            ) : (
                                <>
                                    <span className='font-bold'>
                                        {getHours(PUBLIC_START)} hour
                                        {getHours(PUBLIC_START) == 1 ? '' : 's'}
                                    </span>{' '}
                                    and{' '}
                                </>
                            )}
                            <span className='font-bold'>
                                {getMins(PUBLIC_START)} min
                                {getMins(PUBLIC_START) == 1 ? '' : 's'}
                            </span>
                            .
                        </p>
                    ) : (
                        <p>
                            Minting <span className='text-green-800'>Live</span>
                        </p>
                    )}
                </div>
                <div className='mt-8 flex justify-center'>
                    {!metaMaskInstalled ? (
                        <a
                            href='https://metamask.io/download'
                            target='_blank'
                            className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
                        >
                            Install MetaMask
                        </a>
                    ) : !address ? (
                        <button
                            className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
                            onClick={() => connectWallet()}
                        >
                            Connect Wallet
                        </button>
                    ) : network.chainId != 1 && isProd ? (
                        <p>
                            It looks like you're on the {network.name} network.
                            Please change to Ethereum Mainnet.
                        </p>
                    ) : network.chainId != 4 && !isProd ? (
                        <p>
                            It looks like you're on the {network.name} network.
                            Please change to Rinkeby.
                        </p>
                    ) : address == ownerAddress.toLowerCase() ? (
                        <Admin
                            address={address}
                            network={network}
                            contract={contract}
                            updateBalance={updateBalance}
                            PRICE={PRICE}
                            PUBLIC_LIMIT={PUBLIC_LIMIT}
                            PRESALE_LIMIT={PRESALE_LIMIT}
                        />
                    ) : (
                        <Mint
                            address={address}
                            network={network}
                            contract={contract}
                            updateBalance={updateBalance}
                            PRICE={PRICE}
                            PUBLIC_LIMIT={PUBLIC_LIMIT}
                            PRESALE_LIMIT={PRESALE_LIMIT}
                        />
                    )}
                </div>

                {/* Assets */}
                <Assets
                    address={address}
                    balance={balance}
                    contract={contract}
                    updateBalance={updateBalance}
                />

                {/* Read contract footer */}
                <div className='py-2 text-sm absolute bottom-0 mb-4 w-full flex justify-center left-0'>
                    <a
                        href='https://etherscan.io/address/0xd7425fFe949Dcc6Bf9fE95f7B7a81a0cd21C9447'
                        target='_blank'
                        rel='noreferrer'
                        className='font-medium text-gray-800 underline hover:no-underline'
                    >
                        Verified Smart Contract
                    </a>
                </div>
            </div>
        </div>
    )
}
