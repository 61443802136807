import React, { useEffect, useState } from 'react'

export default function Assets({ address, balance, contract, updateBalance }) {
    const [userTokens, setUserTokens] = useState([])
    const [assets, setAssets] = useState({})

    // On address change, reset assets and refresh balance
    useEffect(() => {
        if (!address) return
        setUserTokens([])
        setAssets({})
        updateBalance()
    }, [address])

    // On balance change, refresh assets
    useEffect(() => {
        if (balance > 0) getUserTokens()
    }, [balance])

    // Fetch indexes for tokens owned by this user
    async function getUserTokens() {
        try {
            let tokens = []
            for (let i = 0; i < balance; i++) {
                tokens.push(
                    (await contract.tokenOfOwnerByIndex(address, i)).toString()
                )
            }
            setUserTokens(tokens)
        } catch (e) {
            console.log(e)
        }
    }

    // Get metadata for tokens owned by this user
    useEffect(() => {
        getAssetMetadata()
    }, [userTokens])
    async function getAssetMetadata() {
        try {
            let metadata = {}
            for (let token of userTokens) {
                let res = await fetch(
                    `https://mint.nftfc.club/metadata/${token}`
                )
                let data = await res.json()
                // Use IPFS gateway to get HTTPS for images
                if (data.image.startsWith('ipfs://'))
                    data.image = data.image.replace(
                        'ipfs://',
                        'https://ipfs.nftfc.club/ipfs/'
                    )
                metadata[token] = data
            }
            setAssets(metadata)
        } catch (e) {
            console.log(e)
        }
    }

    if (balance <= 0) return <></>
    return (
        <div className='pt-10'>
            <h2 className='text-4xl font-bold pb-6'>
                Your {balance > 1 && `${balance} `}NFT{balance == 1 ? '' : 's'}
            </h2>
            <div className='flex flex-wrap gap-y-4 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 place-content-center'>
                {Object.keys(assets).length > 0 ? (
                    Object.keys(assets).map((id) => (
                        <div
                            key={id}
                            className='group relative bg-gray-100 border border-gray-100 rounded-lg flex flex-col overflow-hidden'
                        >
                            <div className='bg-gray-200 group-hover:opacity-75 sm:h-96'>
                                <img
                                    src={assets[id].image}
                                    alt={assets[id].name}
                                    className='w-full h-full object-center object-cover sm:w-full sm:h-full'
                                />
                            </div>
                            <div className='flex-1 p-4 space-y-2 flex flex-col'>
                                <h3 className='text-sm font-medium text-gray-900'>
                                    <a
                                        href={`https://opensea.io/assets/0xd7425fFe949Dcc6Bf9fE95f7B7a81a0cd21C9447/${id}`}
                                        target='_blank'
                                        rel='no-referrer'
                                    >
                                        <span
                                            aria-hidden='true'
                                            className='absolute inset-0'
                                        />
                                        {assets[id].name}
                                    </a>
                                </h3>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    )
}
